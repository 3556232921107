import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth/auth.service";
import { SessionService } from "../../services/session/session.service";
import { VERSION, REVISION } from "../../../environments/version";
import { JwtDecodeService } from "../../services/jwt_decode/jwt-decode.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  locales = require("../../../assets/i18n/en.json");
  VERSION = `v${VERSION.substr(2)}(r${REVISION})`;
  constructor(
    public authService: AuthenticationService,
    public session: SessionService,
    private router: Router,
    private jwtDecode: JwtDecodeService
  ) {
    if (this.jwtDecode.decodeToken(localStorage.getItem("idToken")).success)
      this.router.navigate(["form"]);
  }

  ngOnInit() {}

  // Login With Google
  loginGoogle() {
    this.authService.signInWithGoogle().then(response => {
      if (response.response == "success") {
        this.session.user = response.payload;
        this.session.isLoggedIn = true;
        this.router.navigate(["form"]);
      } else {
        // this.modalService.show(template);
        alert(response.payload);
      }
    });
  }
}
