import { Component, OnInit } from "@angular/core";
import { VERSION, REVISION } from "../../../environments/version";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  locales = require("../../../assets/i18n/en.json");
  VERSION = `v${VERSION.substr(2)}(r${REVISION})`;
  constructor() {}

  ngOnInit() {}
}
