import { Component, OnInit } from "@angular/core";
import { SessionService } from "../../services/session/session.service";
import { ApiService } from "../../services/api/api.service";
import { AuthenticationService } from "../../services/auth/auth.service";
import { JwtDecodeService } from "../../services/jwt_decode/jwt-decode.service";
import { config } from "../../config/config";
import {
  NgForm,
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl
} from "@angular/forms";
import { NavService } from "../../services/nav/nav.service";
import { Router } from "@angular/router";
import { parsePhoneNumberFromString } from "libphonenumber-js";
@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"]
})
export class FormComponent implements OnInit {
  config = config;
  formGroup: FormGroup;
  package: String = "";
  locales = require("../../../assets/i18n/en.json");

  // Your web app's Firebase configuration

  constructor(
    private navServices: NavService,
    public session: SessionService,
    private api: ApiService,
    private router: Router,
    private auth: AuthenticationService,
    private formBuilder: FormBuilder,
    private jwtDecode: JwtDecodeService
  ) {}

  ngOnInit() {
    this.buildForm();
  }
  buildForm() {
    let groups = {};
    groups["package_sold"] = ["", Validators.required];
    groups["image_upload"] = ["", Validators.required];

    Object.keys(config.inputFields).forEach(element => {
      config.inputFields[element].forEach(element => {
        groups[element.id] = ["", this.getFieldValidators(element.validators)];
      });
    });

    Object.keys(config.textAreaFields).forEach(element => {
      config.textAreaFields[element].forEach(element => {
        groups[element.id] = ["", this.getFieldValidators(element.validators)];
      });
    });

    this.formGroup = this.formBuilder.group(groups);
  }

  getFieldValidators(validators) {
    let validationFields = [];
    let validationKeys = Object.keys(validators);

    if (validationKeys.length == 0) return validationFields;
    validationKeys.forEach(validator => {
      if (validator == "required") validationFields.push(Validators.required);
      else if (validator == "phoneNumberValidator")
        validationFields.push(this.phoneNumberValidator);
      else validationFields.push(Validators[validator](validators[validator]));
    });

    return validationFields;
  }

  phoneNumberValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (
      (control.value !== undefined && isNaN(control.value)) ||
      parsePhoneNumberFromString(control.value) == undefined ||
      (parsePhoneNumberFromString(control.value) != undefined &&
        !parsePhoneNumberFromString(control.value).isValid())
    ) {
      return { invalidPhoneNumber: true };
    }
    return null;
  }
  // submitForm() {
  //   if (this.session.isUploading)
  //     alert(this.locales.uploading_attachments_alert);
  //   else {
  //     if (!this.jwtDecode.decodeToken(localStorage.getItem("idToken")).success)
  //       alert(this.locales.unauthorized_token);
  //     else {
  //       this.session.showLoader = true;

  //       this.api.fillJiraStack();
  //     }
  //   }
  // }

  submit() {
    if (this.formGroup.valid) {
      this.session.formData = this.formGroup.value;
      this.api.createJira();
    }
  }
  signOut(): void {
    this.auth.signOut().then(response => {
      this.session.isLoggedIn = false;
      this.session.user = null;
      this.router.navigate(["auth/login"]);
    });
  }
}
