import { Injectable, Output } from "@angular/core";
import * as firebase from "firebase/app";
import { SessionService } from "../session/session.service";
import { environment } from "../../../environments/environment";
import { EventEmitter } from "protractor";

@Injectable({
  providedIn: "root"
})
export class FirebaseService {
  downloadURLs = [];
  storageRef;
  canceledTask;
  locales = require("../../../assets/i18n/en.json");

  constructor(public session: SessionService) {}

  initializeFirebaseApp() {
    firebase.initializeApp(environment.firebase);
    this.storageRef = firebase.storage().ref();
  }

  uploadToStorage(upload, fileName) {
    this.session.uploadTasks[fileName] = {
      task: this.storageRef.child(`/images/${upload.name}`).put(upload),
      done: false
    };

    // this.session.progress.push(0);
    // let lastIndex = this.session.progress.length - 1;
    return new Promise((res, rej) => {
      this.session.uploadTasks[fileName].task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        () => {
          // upload in progress;
          this.session.isUploading = true;
          this.session.uploadTasks[fileName].progress =
            (this.session.uploadTasks[fileName].task.snapshot.bytesTransferred /
              this.session.uploadTasks[fileName].task.snapshot.totalBytes) *
            100;

          if (this.session.uploadTasks[fileName].progress == 100) {
            this.session.uploadTasks[fileName].done = true;
            if (this.doneUpload()) this.session.isUploading = false;
          }
        },
        error => {
          console.log("error", error);
          if (error.code == "storage/canceled") {
            // upload failed or canceled

            this.session.uploadTasks[fileName].done = true;
            this.session.uploadTasks[fileName].progress = 100;
            if (this.doneUpload()) this.session.isUploading = false;
          } else {
            alert(this.locales.error_uploading_media);
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          this.session.uploadTasks[fileName].task.snapshot.ref
            .getDownloadURL()
            .then(url => {
              // console.log("url", url);
              this.session.uploadTasks[fileName].url = url;
              res(url);
            });
        }
      );
    });
  }

  doneUpload() {
    let doneUploading = true;
    Object.keys(this.session.uploadTasks).map(key => {
      if (!this.session.uploadTasks[key].done) doneUploading = false;
    });
    return doneUploading;
  }

  cancelUpload(fileName) {
    if (this.session.uploadTasks[fileName]) {
      this.canceledTask = fileName;
      this.session.uploadTasks[fileName].task.cancel();
    }
  }
}
