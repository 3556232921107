import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { RouterModule } from "@angular/router";
import { FileUploadModule } from "ng2-file-upload/ng2-file-upload";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { CreatedTaskDetailsComponent } from "./pages/created-task-details/created-task-details.component";
import { LoginComponent } from "./pages/login/login.component";
import { ToastrModule } from "ngx-toastr";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { CookieService } from "ngx-cookie-service";
import { ImageUploaderComponent } from "./components/image-uploader/image-uploader.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { HeaderComponent } from "./components/header/header.component";
import { NavService } from "./services/nav/nav.service";
import { environment } from "../environments/environment";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { TextareaFieldComponent } from "./components/textarea-field/textarea-field.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { InputFieldComponent } from "./components/input-field/input-field.component";
import { FormComponent } from "./pages/form/form.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FooterComponent } from './components/footer/footer.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 16,
  url: "https://httpbin.org/post",
  addRemoveLinks: true
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FormComponent,
    FeatherIconsComponent,
    SidebarComponent,
    InputFieldComponent,
    TextareaFieldComponent,
    ImageUploaderComponent,
    CreatedTaskDetailsComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    DropzoneModule,
    NgbModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule
  ],
  providers: [
    { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG },
    CookieService,
    NavService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
