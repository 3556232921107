import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ViewEncapsulation,
  Input
} from "@angular/core";
import { SessionService } from "../../services/session/session.service";
import { FirebaseService } from "../../services/firebase/firebase.service";
import { FileUploader } from "ng2-file-upload";
@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ImageUploaderComponent implements OnInit {
  @Input() formGroup;
  progress = 0;
  downloadUrls = [];
  file;
  locales = require("../../../assets/i18n/en.json");
  uploader: FileUploader = new FileUploader({
    url: " ",
    isHTML5: true
  });
  constructor(
    public session: SessionService,
    private firebase: FirebaseService
  ) {}

  ngOnInit() {}

  cancelUpload(args: any): void {
    this.firebase.cancelUpload(args.file.rawFile.name);
    this.uploader.removeFromQueue(args);
  }

  removeItem(file) {
    this.downloadUrls.filter((item, index) => {
      if (item.fullName == file.file.rawFile.name)
        this.downloadUrls.splice(index, 1);
    });
    this.formGroup.controls["image_upload"].setValue(this.downloadUrls);
    this.uploader.removeFromQueue(file);
  }

  uploadFile() {
    this.file = this.uploader.queue[
      this.uploader.queue.length - 1
    ].file.rawFile;

    if (this.isDuplicate(this.uploader.queue[this.uploader.queue.length - 1])) {
      this.uploader.queue.pop();
      alert(this.locales.duplicate_file);
    } else {
      if (this.file["size"] / Math.pow(1024, 2) > 15) {
        alert(this.locales.max_file_size);
        this.uploader.queue.pop();
      } else {
        this.firebase
          .uploadToStorage(this.file, this.file["name"])
          .then(res => {
            this.downloadUrls.push({ url: res, fullName: this.file.name });
            this.formGroup.controls["image_upload"].setValue(this.downloadUrls);
          });
      }
    }
  }

  isDuplicate(file) {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (
        this.uploader.queue[i].file.name == file.file.name &&
        i != this.uploader.queue.length - 1
      )
        return true;
    }

    return false;
  }
}
