import { Component, OnInit, Input } from "@angular/core";
import { SessionService } from "../../services/session/session.service";

@Component({
  selector: "app-textarea-field",
  templateUrl: "./textarea-field.component.html",
  styleUrls: ["./textarea-field.component.css"]
})
export class TextareaFieldComponent implements OnInit {
  @Input() data: Object;
  @Input() formGroup;
  inputValue = "";

  constructor(public session: SessionService) {}

  ngOnInit() {
    this.initVars();
  }

  initVars() {
    this.inputValue = this.data["placeholder"];
  }
}
