import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";

import { FormComponent } from "./pages/form/form.component";
import { CreatedTaskDetailsComponent } from "./pages/created-task-details/created-task-details.component";
import { AuthGuardService } from "./services/auth-guard/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "form",
    component: FormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "success",
    component: CreatedTaskDetailsComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: "**",
    redirectTo: "login"
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
