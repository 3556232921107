let locales = require("../../assets/i18n/en.json");
import { SessionService } from "../services/session/session.service";
export const config = {
  inputFields: {
    sales_information: [
      {
        id: "sales_person",
        placeholder: " ",
        label: locales.sales_person,
        validators: { required: true },
        type: "text"
      }
    ],
    customer_information: [
      {
        id: "company_name",
        placeholder: " ",
        label: locales.company_name,
        validators: { required: true },
        type: "text"
      },
      {
        id: "customer_name",
        placeholder: " ",
        label: locales.customer_name,
        validators: {
          required: true,
          maxLength: 128
        },
        type: "text"
      },
      {
        id: "person_name",
        placeholder: " ",
        label: locales.person_name,
        validators: { required: true }
      },
      {
        id: "customer_phone",
        placeholder: " ",
        label: locales.customer_phone,
        validators: {
          required: true,
          // maxLength: 8,
          // minLength: 8,
          phoneNumberValidator: true
        },
        type: "number"
      },
      {
        id: "customer_email",
        placeholder: " ",
        label: locales.customer_email,
        validators: {
          required: true,
          pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        type: "email"
      }
    ],
    instructions: [
      {
        id: "preferred_time",
        placeholder: " ",
        label: locales.preferred_time_to_call,
        validators: {},
        type: "text"
      }
    ]
  },
  textAreaFields: {
    instructions: [
      {
        label: locales.special_instructions,
        validators: {},
        id: "special_instructions",
        placeholder: " "
      },

      {
        label: locales.customer_about,
        validators: {},
        id: "customer_about",
        placeholder: " "
      },
      {
        label: locales.customer_requirements,
        validators: {},
        id: "customer_requirements",
        placeholder: " "
      }
    ]
  },
  packageOptions: [
    { id: "select_package", title: locales.select_package, disabled: true },
    { id: "basic_package", title: locales.basic_package, disabled: false },
    {
      id: "essential_package",
      title: locales.essential_package,
      disabled: false
    },
    {
      id: "campaign_package",
      title: locales.campaign_package,
      disabled: false
    },
    {
      id: "online_identity_package",
      title: locales.online_identity_package,
      disabled: false
    },
    {
      id: "social_media_package",
      title: locales.social_media_package,
      disabled: false
    },
    {
      id: "social_exposure_package",
      title: locales.social_exposure_package,
      disabled: false
    }
  ],
  sideBarMenuItems: [{ name: locales.customer_onboarding, active: true }],
  whiteList: ["itxi.net", "beirut.com", "itx.com.lb"],
  jiraConfig: formData => {
    let defaultJiraBody = `This customer has been sold a ${
      formData.package_sold
    } by ${formData.sales_person}. \n \n Please do the following:
    * Prepare an invoice for ${
      formData.company_name
    } (see full details attached) and send to ${
      formData.sales_person
    }\n * Contact ${formData.person_name} by phone - their number is ${
      formData.customer_phone
    } and their email is ${formData.customer_email}
     ${
       formData.preferred_time
         ? "* The preferred time to contact the customer is " +
           formData.preferred_time
         : ""
     }
     \n \n 
     ${
       formData.customer_about
         ? "*About The Customer:* \n " + formData.customer_about
         : ""
     }
     ${
       formData.customer_requirements
         ? "\n *Customer Requirements:* \n" + formData.customer_requirements
         : ""
     }
     ${
       formData.special_instructions
         ? "\n *Special Instructions:* \n" + formData.special_instructions
         : ""
     }`;
    return {
      basic_package: [
        {
          fields: {
            project: { key: "AUT" },
            summary: `Onboard & Produce  ${formData.customer_name} Customer's ${formData.package_sold}`,
            description: defaultJiraBody,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        }
      ],
      essential_package: [
        {
          fields: {
            project: { key: "AUT" },
            summary: `Onboard & Produce  ${formData.customer_name} Customer's ${formData.package_sold}`,
            description: defaultJiraBody,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        }
      ],
      campaign_package: [
        {
          fields: {
            project: { key: "AUT" },
            summary: `Onboard & Produce  ${formData.customer_name} Customer's ${formData.package_sold}`,
            description: `This customer has been sold a ${formData.package_sold} by ${formData.sales_person}. \n Please prepare an invoice for ${formData.company_name} (see full details attached) and send to ${formData.sales_person}`,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Epic"
            },
            customfield_10601: `${formData.customer_name} for ${formData.package_sold}`
          },
          transition: {
            id: 51
          }
        },
        {
          fields: {
            project: { key: "AUT" },
            summary: `Onboard & Produce  ${formData.customer_name} Customer's Article`,
            description: `This customer has been sold a ${
              formData.package_sold
            } by ${
              formData.sales_person
            }. \n \n Please do the following \n *  Contact ${
              formData.person_name
            } by phone - their number is ${
              formData.customer_phone
            } and their email is ${formData.customer_email}
             ${
               formData.preferred_time
                 ? "* The preferred time to contact the customer is " +
                   formData.preferred_time
                 : ""
             }
             \n \n 
             ${
               formData.customer_about
                 ? "*About The Customer:* \n " + formData.customer_about
                 : ""
             }
             ${
               formData.customer_requirements
                 ? "\n *Customer Requirements:* \n" +
                   formData.customer_requirements
                 : ""
             }
             ${
               formData.special_instructions
                 ? "\n *Special Instructions:* \n" +
                   formData.special_instructions
                 : ""
             }`,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        },
        {
          fields: {
            project: { key: "AUT" },
            summary: `Onboard & Produce  ${formData.customer_name} Customer's Video`,
            description: `This customer has been sold a ${
              formData.package_sold
            } by ${
              formData.sales_person
            }. \n \n Please do the following \n *  Contact ${
              formData.person_name
            } by phone - their number is ${
              formData.customer_phone
            } and their email is ${formData.customer_email}
             ${
               formData.preferred_time
                 ? "* The preferred time to contact the customer is " +
                   formData.preferred_time
                 : ""
             }
             \n \n 
             ${
               formData.customer_about
                 ? "*About The Customer:* \n " + formData.customer_about
                 : ""
             }
             ${
               formData.customer_requirements
                 ? "\n *Customer Requirements:* \n" +
                   formData.customer_requirements
                 : ""
             }
             ${
               formData.special_instructions
                 ? "\n *Special Instructions:* \n" +
                   formData.special_instructions
                 : ""
             }`,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        }
      ],
      online_identity_package: [
        {
          fields: {
            project: { key: "AUT" },
            summary: `Onboard ${formData.customer_name} for ${formData.package_sold}`,
            description: defaultJiraBody,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        }
      ],
      social_media_package: [
        {
          fields: {
            project: { key: "AUT" },
            summary: `Onboard ${formData.customer_name} for ${formData.package_sold}`,
            description: defaultJiraBody,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        }
      ],
      social_exposure_package: [
        {
          fields: {
            project: { key: "AUT" },
            summary: `${formData.customer_name} - Social Media Contract`,
            description: `This customer has been sold a ${formData.package_sold} by ${formData.sales_person}. \n Please prepare an invoice for ${formData.company_name} (see full details attached) and send to ${formData.sales_person}`,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Epic"
            },
            customfield_10601: `${formData.customer_name} for ${formData.package_sold}`
          },
          transition: {
            id: 51
          }
        },
        {
          fields: {
            project: { key: "AUT" },
            summary: `Onboard ${formData.customer_name} Customer for Social Media Management`,
            description: `This customer has been sold a ${
              formData.package_sold
            } by ${
              formData.sales_person
            }. \n \n Please do the following \n *  Contact ${
              formData.person_name
            } by phone - their number is ${
              formData.customer_phone
            } and their email is ${formData.customer_email}
             ${
               formData.preferred_time
                 ? "* The preferred time to contact the customer is " +
                   formData.preferred_time
                 : ""
             }
             \n \n 
             ${
               formData.customer_about
                 ? "*About The Customer:* \n " + formData.customer_about
                 : ""
             }
             ${
               formData.customer_requirements
                 ? "\n *Customer Requirements:* \n" +
                   formData.customer_requirements
                 : ""
             }
             ${
               formData.special_instructions
                 ? "\n *Special Instructions:* \n" +
                   formData.special_instructions
                 : ""
             }`,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        },
        {
          fields: {
            project: { key: "AUT" },
            summary: `Weekly Call - ${formData.customer_name}`,
            description: `Weekly call with ${formData.person_name} set on: 
     
            DAY at XX:XX xx 
            DAY at XX:XX xx 
            
            ${
              formData.preferred_time
                ? "The preferred time to contact the customer is " +
                  formData.preferred_time
                : ""
            }
            
            Contact the customer by phone - their number is ${
              formData.customer_phone
            } and their email is ${formData.customer_email}`,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        },
        {
          fields: {
            project: { key: "AUT" },
            summary: `${formData.customer_name} Social Media - Month 1`,
            description: `Month 1 of deal is (MONTH -- to MONTH -- ):  
     
            *Media Collection:* 
            
            • Call ${formData.person_name} to set date for shooting (2 weeks before expected media publishing) - their number is ${formData.customer_phone} 
            • Confirm availability of person doing shooting 
            • Post edit images 
            
            *Publishing on Sendible:* 

           \n • Create Sendible account for customer \n • Send instructions email to customer
            • Add captions to posts and schedule posts 
            • Upload images to sendible 
            • Send posts to customer for approval `,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        },
        {
          fields: {
            project: { key: "AUT" },
            summary: `${formData.customer_name} Social Media - Month 2`,
            description: `Month 2 of deal is (MONTH -- to MONTH -- ):  
     
            *Media Collection:* 
            
            • Call ${formData.person_name} to set date for shooting (2 weeks before expected media publishing) - their number is ${formData.customer_phone} 
            • Confirm availability of person doing shooting 
            • Post edit images 
            
            *Publishing on Sendible:* 

            • Add captions to posts and schedule posts 
            • Upload images to sendible 
            • Send posts to customer for approval `,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        },
        {
          fields: {
            project: { key: "AUT" },
            summary: `${formData.customer_name} Social Media - Month 3`,
            description: `Month 3 of deal is (MONTH -- to MONTH -- ):  
     
            *Media Collection:* 
            
            • Call ${formData.person_name} to set date for shooting (2 weeks before expected media publishing) - their number is ${formData.customer_phone} 
            • Confirm availability of person doing shooting 
            • Post edit images 
            
            *Publishing on Sendible:* 

            • Add captions to posts and schedule posts 
            • Upload images to sendible 
            • Send posts to customer for approval `,
            assignee: { name: "Reem" },
            issuetype: {
              name: "Task"
            }
          },
          transition: {
            id: 51
          }
        }
      ]
    };
  }
};
