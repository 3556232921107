import { Component, OnInit } from "@angular/core";
import { SessionService } from "../../services/session/session.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth/auth.service";
import { NavService } from "../../services/nav/nav.service";
@Component({
  selector: "app-created-task-details",
  templateUrl: "./created-task-details.component.html",
  styleUrls: ["./created-task-details.component.css"]
})
export class CreatedTaskDetailsComponent implements OnInit {
  locales = require("../../../assets/i18n/en.json");
  taskInfo;
  constructor(
    public session: SessionService,
    private router: Router,
    private authService: AuthenticationService,
    private navServices: NavService
  ) {}

  ngOnInit() {
    this.taskInfo = JSON.parse(localStorage.getItem("createdJiraInfo"));
  }

  createNewTask() {
    this.goTo("form");
  }
  signOut(): void {
    this.authService.signOut().then(response => {
      this.session.isLoggedIn = false;
      this.session.user = null;
      this.goTo("auth/login");
    });
  }

  goTo(route) {
    this.router.navigate([route]);
  }
}
