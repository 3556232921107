import { Injectable } from "@angular/core";
import * as jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root"
})
export class JwtDecodeService {
  constructor() {}
  decodeToken(token) {
    try {
      let decoded_token = jwt_decode(token);
      return { success: true, message: decoded_token };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }
}
