import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "../session/session.service";
import { endpoints } from "../../config/endpoints";
import { Router } from "@angular/router";
import { config } from "../../config/config";
import { TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: "root"
})
export class ApiService {
  jiraConfig;
  formData;
  epicId = "";
  taskNumber = 0;
  locales = require("../../../assets/i18n/en.json");

  constructor(
    private http: HttpClient,
    public session: SessionService,
    private router: Router,
    private translate: TranslateService
  ) {}

  createJira() {
    this.session.isLoading = true;
    this.formData = this.session.formData;
    let packageKey = this.getPackageKey(this.formData["package_sold"]);
    this.jiraConfig = config.jiraConfig(this.formData)[packageKey];
    let jiraBody = this.jiraConfig[this.taskNumber];
    if (this.epicId) jiraBody.fields["customfield_10600"] = this.epicId;
    this.http
      .post(`${endpoints.functions}/createJiraIssue`, jiraBody, {
        headers: {
          "x-api-key": "dae203d7-a0e4-44b7-b81f-26a9413f7120",
          "access-token": localStorage.getItem("idToken")
        }
      })
      .subscribe(
        res => {
          if (res["status"] == 200 && res["payload"].key) {
            if (jiraBody.fields.issuetype.name == "Epic") {
              this.epicId = res["payload"].key;
            }
            if (this.taskNumber == 0) {
              localStorage.setItem(
                "createdJiraInfo",
                JSON.stringify({
                  key: res["payload"].key,
                  title: jiraBody.fields.summary
                })
              );
              this.uploadAttachments(res["payload"].key);
            }
            this.taskNumber++;
            if (this.jiraConfig[this.taskNumber]) {
              this.createJira();
            } else {
              this.taskNumber = 0;
              this.epicId = "";
              this.session.isLoading = false;
            }

            this.router.navigate(["success"]);
          } else {
            alert(this.locales.error_creating_jira);
          }
        },
        err => {
          alert(this.locales.error_creating_jira);
        }
      );
  }

  getPackageKey(packageName) {
    return packageName.toLowerCase().replace(/ /g, "_");
  }

  uploadAttachments(jiraKey) {
    this.http
      .post(
        `${endpoints.functions}/uploadAttachments`,
        {
          attachments: this.session.formData.image_upload,
          jiraCode: jiraKey
        },
        {
          headers: {
            "x-api-key": "dae203d7-a0e4-44b7-b81f-26a9413f7120",
            "Content-Type": "application/json",
            "access-token": localStorage.getItem("idToken")
          }
        }
      )
      .subscribe(
        response => {
          if (response["error"]) {
            alert(this.locales.error_uploading_attachments);
          }
        },
        error => {
          alert(this.locales.error_uploading_attachments);
        }
      );
  }
}
