import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class SessionService {
  user = null;
  attachmentsUploaded = false;
  isLoading = false;
  isLoggedIn: boolean = false;
  uploadTasks = {};
  formData;
  isUploading = false;
  submitDisabled: boolean = true;

  constructor() {}
}
