import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { NavService, Menu } from "../../services/nav/nav.service";
import { AuthenticationService } from "../../services/auth/auth.service";
import { SessionService } from "../../services/session/session.service";
import { Router } from "@angular/router";
// import { TranslateService } from "@ngx-translate/core";

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() showSubmitBtn;
  @Output() triggerSubmit = new EventEmitter();
  locales = require("../../../assets/i18n/en.json");
  public openNav: boolean = false;
  public username: string;
  public userEmail: string;
  public photoUrl: string;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    private auth: AuthenticationService,
    public navServices: NavService,
    public session: SessionService,
    private router: Router
  ) {}

  ngOnDestroy() {}

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem("BeirutUser"));
    this.username = userInfo.username;
    this.userEmail = userInfo.email;
    this.photoUrl = userInfo.picture;
  }

  signOut(): void {
    this.auth.signOut().then(response => {
      this.session.isLoggedIn = false;
      this.session.user = null;
      this.router.navigate(["auth/login"]);
    });
  }

  submitForm() {
    this.triggerSubmit.emit("submit");
  }
}
