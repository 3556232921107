import { Component, Input, ViewEncapsulation, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { NavService, Menu } from "../../services/nav/nav.service";
import { AuthenticationService } from "../../services/auth/auth.service";
import { config } from "../../config/config";
import { SessionService } from "../../services/session/session.service";
import { VERSION, REVISION } from "../../../environments/version";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  locales = require("../../../assets/i18n/en.json");
  VERSION = `${this.locales.version} ${VERSION.substr(2)}(r${REVISION})`;
  public menuItems = config.sideBarMenuItems;
  public url: any;
  public fileurl: any;
  public username: string;
  public userEmail: string;
  public photoUrl: string;

  constructor(
    private router: Router,
    public navServices: NavService,
    private auth: AuthenticationService,
    public session: SessionService
  ) {}

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem("BeirutUser"));
    this.username = userInfo.username;
    this.userEmail = userInfo.email;
    this.photoUrl = userInfo.picture;
  }
  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem["children"] && menuItem["children"].includes(item))
        menuItem.active = true;
      if (menuItem["children"]) {
        menuItem["children"].filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a["children"]) return false;
        a["children"].forEach(b => {
          if (a["children"].includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = _event => {
      this.url = reader.result;
    };
  }

  goTo(route) {
    this.router.navigate([route]);
  }

  signOut(): void {
    this.auth.signOut().then(response => {
      this.session.isLoggedIn = false;
      this.session.user = null;
      this.router.navigate(["auth/login"]);
    });
  }
}
