import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SessionService } from "../../services/session/session.service";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from "@angular/forms";

import { parsePhoneNumberFromString } from "libphonenumber-js";

@Component({
  selector: "app-input-field",
  templateUrl: "./input-field.component.html",
  styleUrls: ["./input-field.component.css"]
})
export class InputFieldComponent implements OnInit {
  locales = require("../../../assets/i18n/en.json");
  @Input() data: Object;
  @Input() formGroup;
  inputValue = "";

  constructor(public session: SessionService, private fb: FormBuilder) {}

  ngOnInit() {
    if (this.data["id"] == "sales_person") {
      this.inputValue = JSON.parse(localStorage.getItem("BeirutUser")).username;
    }
    if (this.data["id"] == "customer_phone") {
      this.inputValue = "+961";
    }
  }
}
