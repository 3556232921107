import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { JwtDecodeService } from "../jwt_decode/jwt-decode.service";
import { config } from "../../config/config";
@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  email: string = "";
  loggedIn: boolean = false;
  locales = require("../../../assets/i18n/en.json");

  constructor(private jwtDecode: JwtDecodeService) {}

  signInWithGoogle(): Promise<any> {
    let responseMessage;
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account"
    });

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(response => {
        let currentDomain = response.user.email.split("@")[1];
        for (let i = 0; i < config.whiteList.length; i++) {
          if (currentDomain == config.whiteList[i]) {
            if (
              !this.jwtDecode.decodeToken(response.credential["idToken"])
                .success
            )
              return { response: "fail", payload: "Invalid User Token" };

            localStorage.setItem(
              "BeirutUser",
              JSON.stringify({
                username: response.user.displayName,
                email: response.user.email,
                picture: response.user.photoURL
              })
            );
            localStorage.setItem("idToken", response.credential["idToken"]);

            return { response: "success", payload: response.user };
          }
        }
        return { response: "fail", payload: "Unauthorized Email Address" };
      })
      .catch(error => {
        alert(error);
      });
  }

  signOut(): Promise<any> {
    localStorage.removeItem("BeirutUser");
    localStorage.removeItem("idToken");
    return firebase.auth().signOut();
  }
}
