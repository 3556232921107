import { Component, OnInit } from "@angular/core";
import { FirebaseService } from "./services/firebase/firebase.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "endless";

  constructor(
    private firebase: FirebaseService,
    private router: Router,
    private translate: TranslateService
  ) {}
  ngOnInit() {
    this.translate.setDefaultLang("en");
    this.translate.use("en");
    this.firebase.initializeFirebaseApp();
  }
}
