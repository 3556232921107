import { Injectable, NgModule } from "@angular/core";
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from "@angular/router";
import { SessionService } from "../session/session.service";
import { ApiService } from "../api/api.service";
import { JwtDecodeService } from "../jwt_decode/jwt-decode.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService {
  constructor(
    private router: Router,
    public session: SessionService,
    private jwtDecode: JwtDecodeService
  ) {}

  isAuthorized() {
    let user = this.session.user;
    let isLoggedIn = this.session.isLoggedIn;
    // if (user == null || !isLoggedIn) return false;

    // let email = user.email.split("@")[1];
    // if (email != "itxi.net") {
    //   return false;
    // }

    if (!this.jwtDecode.decodeToken(localStorage.getItem("idToken")).success)
      return false;
    return true;
  }

  canActivate(): boolean {
    if (!this.isAuthorized()) {
      this.router.navigate(["login"]);

      return false;
    }
    return true;
  }
}
